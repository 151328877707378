.pro-title{
    background-image: url(../images/title-background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    font-size: 20em;
    color: azure;
    text-align: center;
    margin: 0 auto;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    text-shadow: 2px 2px gray;
    animation: 2s titleAnim forwards;
  }

  @keyframes titleAnim{
    from{
        font-size: 20em;
    }
    to{
        font-size: 5em;
    }
  }

  
.projects-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(hsl(0, 1%, 86%), white);
    }

.projects{
    flex : 1;
}

.projects:hover{
    text-shadow: 0 0 white;
}

.card{
    flex : 1;
    border: 1px solid black;
    width: 25em;
    height: 20em;
    border-radius: 1em;
    margin: 2em 2em;
    box-shadow: 2px 2px black;
    align-items: center;
    justify-content: center;
    background-image: url(../images/card-background.jpg);
    opacity: 0;
    animation: 2s cardAppear forwards;
    animation-delay: 0.25s;
}

@keyframes cardAppear{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

.card-title{
    color: azure;
    font-size: 2em;
    text-align: center;
}

.card-description{
    color: white;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
}

.card-instruct{
    color: azure;
    text-align: center;
}