.navbar{
    display: flex;
    flex-wrap: wrap;
    background-image: linear-gradient(hsl(216, 90%, 52%), hsl(216, 77%, 41%));
    height: 3em;
    width: 100%;
    border-bottom: 1px solid black;
}

.navbar-elements{
    flex: 1;
    color: white;
    font-size: 1.5em;
    text-align: center;
    margin-top: 8px;
}

a{
    text-decoration: none;
    color: white;
    transition-duration: 0.5s;
}

a:hover{
    text-shadow: 3px 3px hsl(226, 100%, 3%);
}
