.title{
  background-image: url(./images/title-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  font-size: 5em;
  color: azure;
  text-align: center;
  margin: 0 auto;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  animation: 2s extend forwards ease-in;
  text-shadow: 2px 2px gray;
}

@keyframes extend{
  from{
    height: 4em;
  }

  to{
    height: 1.5em;
  }
}

.AboutMe{
  width: 95%;
  margin: 0 auto;
}

.AboutMetitle{
  color: hsl(239, 88%, 27%);
  text-align: center;
  font-size: 2.5em;
  opacity: 0;
  animation: 2s appear forwards;
  animation-delay: 0.25s;
}

.abme{
  text-align: justify;
  font-size: 1.2em;
  padding-bottom: 5px;
  opacity: 0;
  padding-left: 3px;
  animation: 2s appear forwards;
  animation-delay: 0.25s;
}

@keyframes appear{
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
}

.ASG-container{
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
}

.ASG{
  flex: 1;
  background-image: linear-gradient(to right, rgb(31, 85, 221), rgb(48, 110, 234), rgb(31, 85, 221));
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
}

.ASG-titles{
  font-size: 2em;
  text-align: center;
  color: azure;
  text-shadow: 2px 2px gray;
}

.ASG-points{
  font-size: 1em;
  padding-right: 20px;
  color: azure;
  text-align:center;
  list-style-position: inside;
  padding-bottom: 3px;
}

.resume-title{
  text-align: center;
  color: black;
  font-size: 2.5em;
}

.res{
  width: 50%;
  margin: 0 auto;
}

.download{
  margin: 2em auto;
  width: 6em;
}

#dwnld{
  background-color: hsl(217, 88%, 55%);
  border-radius: 20px;
  font-size: 1.5em;
  padding: 5px 10px;
  box-shadow: 2px 2px gray;
}