.contact-info{
    background-image: linear-gradient(hsl(225, 4%, 56%),hsl(228, 4%, 44%));
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 4em;
    margin-top: 3em;
}

.contact{
    flex: 1;
    text-align: center;
    margin-top: 4px;
}